import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const BuscarPlantao = loadable(() => import('@screens/buscar_plantao/BuscarPlantao'));

function Index(props) {
  return (
    <Layout {...props}>
      <BuscarPlantao {...props} />
    </Layout>
  );
}

export default Index;
